import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHouseChimney, faAngleRight } from "@fortawesome/free-solid-svg-icons"

const Breadcrumb = ({ paths }) => (
  <>
    <div className="bg-light-gray py-6">
      <div className="container max-w-screen-xl">
        <ul className="breadcrumbs list-none">
          <li className="inline-block font-normal cursor-default">
            <a
              href="/"
              className="transition-colors duration-300 ease-out hover:text-blue text-dark-gray inline-flex items-center"
            >
              <FontAwesomeIcon
                icon={faHouseChimney}
                className="mb-[2px] mr-2"
              />{" "}
              Home
            </a>
            <FontAwesomeIcon
              icon={faAngleRight}
              className="text-dark-gray px-2 inline-block"
              size="sm"
            />
          </li>

          {paths &&
            paths.map((path, index) => (
              <li
                key={path.name}
                className={`inline-block cursor-default relative font-normal ${
                  index === paths.length - 1 ? "text-medium-gray" : ""
                }`}
              >
                <a
                  href={`/${path.path}`}
                  className={`transition-colors duration-300 ease-out hover:text-blue ${
                    index === paths.length - 1
                      ? "text-medium-gray cursor-default"
                      : "text-dark-gray"
                  } font-normal`}
                >
                  {path.name}
                </a>
                {index < paths.length - 1 && (
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    className="text-dark-gray px-2"
                    size="sm"
                  />
                )}
              </li>
            ))}
        </ul>
      </div>
    </div>
  </>
)

export default Breadcrumb
